@import '~/styles/variables';

.container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;

  padding-top: 22px;
  border-bottom: 1px solid $secondaryGray;

  @include mobile {
    padding: 0;
    border: unset;

    flex-direction: column;
    gap: 0;
  }
}

.settings {
  width: 100%;
  max-width: calc(240px + 24px * 2);
  height: 100%;
  max-height: 455px;

  padding: 0 24px 20px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  @include mobile {
    padding: 24px 16px;
    max-width: unset;
    gap: 16px;
  }
}

// show chords
.showChordsContainer {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.showChordsInnerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  span {
    font-size: 14px;
    font-weight: 600;
    color: #dfdfe3;
  }
}
.showChordsSwitch {
  width: 48px;
  height: 24px;
}
.showChordsSwitchMarker {
  width: 18px;
  height: 18px;
  background: $lightTheme;
}
.showChordsSwitchActive {
  & > div {
    background: $secondaryBlue20 !important;
  }
  .showChordsSwitchMarker {
    margin-left: 24px !important;
  }
}
.showChordsInfo {
  width: 18px;
  cursor: pointer;
}

.divider {
  width: 1px;
  height: 100%;
  background: $blogTitleColor;
}

.pianoRoll {
  width: 100%;
  height: 100%;

  overflow: hidden;
}

.content {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  overflow: hidden;

  @include tablet {
    width: calc(100vw - 250px - 16px * 4 - 1px);
  }

  @include mobile {
    width: 100%;
  }
}

.contentHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  padding: 0 24px 16px;

  @include tablet {
    padding: 0 16px 16px;
  }
  @include smallTablet {
    display: none;
  }

  & > div {
    flex-direction: row !important;
    align-items: center;
    gap: 12px;
  }
}

.contentTabContent {
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex: 1;
}
